@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;

* {
  scrollbar-width: 12px;
  
  scrollbar-color: #F3F4F6 #D1D5DB ;

  box-sizing: border-box;
  font-family: "Nunito" !important;
}

*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  border-radius: 5px;
}

*::-webkit-scrollbar-track {
  background: #F3F4F6;
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: #D1D5DB;
  border-radius: 14px;
  border: 3px solid #F3F4F6;
}

@tailwind components;
@tailwind utilities;

@layer base {
  [type='text']:focus, [type='email']:focus, [type='url']:focus, [type='password']:focus, [type='number']:focus, [type='date']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='week']:focus, [multiple]:focus, textarea:focus, select:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #8B5CF6;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
    border-color: #8B5CF6;
    border-radius: 6px;
}
[type='text'], [type='email'], [type='url'], [type='password'], [type='number'], [type='date'], [type='datetime-local'], [type='month'], [type='search'], [type='tel'], [type='time'], [type='week'], [multiple], textarea, select {
  border-color: #D1D5DB;
  border-radius: 6px;
}

[type='radio'] {
  color: #8B5CF6;
  cursor: pointer;
  border: none;
  background-color: #e5e7eb;
  display: inherit;
}

[type='radio']:focus {
  outline: none;
  box-shadow: none;
}

  h2 {
    @apply font-bold lg:text-[20px] text-gray-900 text-base
  }
  h4 {
    @apply text-gray-500 lg:text-sm text-[12px]
  }
}
